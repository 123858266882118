import React from 'react'
import { graphql } from 'gatsby'
import { HelmetDatoCms } from 'gatsby-source-datocms'
import Img from 'gatsby-image'
import Form from "../components/form"
import { isFirefox, isIOS } from "react-device-detect";
import { canUseWebP } from "react-img-webp";

const IndexPage = ({ data: { about } }) => (
    <article className="sheet">
      <HelmetDatoCms seo={about.seoMetaTags} />
      <div className="sheet__inner">
        <h1 className="sheet__title">{about.title}</h1>
        <p className="sheet__lead">{about.subtitle}</p>
        <div className="sheet__gallery">
          {(canUseWebP() || (isFirefox && !isIOS)) ? 
            <Img fluid={about.photo.webp} backgroundColor="#3b6a6f82" />
            :
            <Img fluid={about.photo.auto} backgroundColor="#3b6a6f82" />
          }
        </div>
        <div
          className="sheet__body"
          dangerouslySetInnerHTML={{
            __html: about.bio,
          }}
        />
      </div>
    </article>
)

export default IndexPage

export const query = graphql`
  query AboutQuery {
    about: datoCmsAboutPage {
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      title
      subtitle
      photo {
        auto: fluid(maxWidth: 600, imgixParams: { auto: "compress, format" }) {
          ...GatsbyDatoCmsSizes_noBase64
        }
        webp: fluid(maxWidth: 600, imgixParams: { fm:"webp", auto: "compress" }) {
          ...GatsbyDatoCmsSizes_noBase64
        }
      }
      bio
    }
  }
`
